body, html {
    height: 100%;
    background-color: #f1f6f8;
}

#root{
    background-color: #f1f6f8;
    height: 100%;
}

::-webkit-scrollbar {
    width: 12px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: grey;
    border-radius: 10px;
}


::-webkit-scrollbar-thumb:hover {
    background: #444242;
}

.form_info {
    width: 400px;
    position: absolute;
    z-index: 999999;
    
  }
  
  .form__input {
    display: flex;
    width: 70%;
    background-color: #f5f5f5;
    padding: 10px;
    box-shadow: 0 1px 3px rgba(110, 110, 110, 0.12), 0 1px 2px rgba(63, 62, 62, 0.24);
  }
  
  .form__edit-input {
    outline: none;
    border: none;
    font-size: 20px;
    width: 70%;
    
  }
  
  .visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  